<template>
  <v-card>
    <v-card-title>
      {{ $t('projects.general.conclusion') }}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-textarea
            v-model="data.cause"
            :error-messages="errors.cause"
            :label="formMixin_getRequiredFieldLabel($t('projects.labels.cause'))"
            @input="formMixin_clearErrors('cause')"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea
            v-model="data.lesson_learned"
            :error-messages="errors.lesson_learned"
            :label="formMixin_getRequiredFieldLabel($t('projects.labels.lesson_learned'))"
            @input="formMixin_clearErrors('lesson_learned')"
          />
        </v-col>

        <v-col cols="12">
          <v-select
              v-model="data.time_spent"
              :items="projectIssueTimeSpentOptions"
              :error-messages="errors['time_spent']"
              :label="$t('projects.labels.time_spent')"
              @change="formMixin_clearErrors('time_spent')"
            />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">
        {{ $t('general.controls.cancel') }}
      </v-btn>
      <v-btn :disabled="loading" :loading="loading" color="primary" text @click="onSubmit">
        {{ $t('general.controls.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import formMixin from '@/mixins/form-mixin';

export default {
  name: 'ProjectIssueConclusionForm',

  mixins: [formMixin],

  props: {
    formItem: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      data: {},
    };
  },

  computed: {
    ...mapGetters('projectIssues', [
      'projectIssueTimeSpentOptions'
    ])
  },

  created() {
    this.data = JSON.parse(JSON.stringify(this.formItem));
  },

  methods: {
    onSubmit() {
      this.$emit('save', this.data);
    },

    close() {
      this.$emit('cancel');
    },
  },
};
</script>
