<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="800"
    scrollable
    persistent
  >
    <ProjectIssueConclusionForm
      :form-item="editedProjectIssue"
      :errors="projectIssueValidationErrors"
      :loading="loading"
      @clear:errors="CLEAR_PROJECT_ISSUE_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProjectIssueConclusionForm from '@/components/forms/ProjectIssueConclusionForm';
import store from '@/store';

export default {
  name: 'ConcludeProjectIssue',

  components: { ProjectIssueConclusionForm },

  computed: {
    ...mapState('projectIssues', ['editedProjectIssue', 'projectIssueValidationErrors']),

    loading() {
      const { loading } = this.$store.getters;
      return (
        loading[`put:api/project-issues/${this.editedProjectIssue.id}`] ||
        loading[`put:api/issue-conclusion/${this.editedProjectIssue.id}`]
      );
    },
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('projectIssues/editProjectIssue', +to.params.projectIssueId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.getBackRoute(to.params.projectId));
      });
  },

  methods: {
    ...mapActions('projectIssues', ['concludeProjectIssue']),
    ...mapMutations('projectIssues', ['CLEAR_PROJECT_ISSUE_VALIDATION_ERRORS']),

    async onSave(issue) {
      await this.concludeProjectIssue({
        ...issue,
        status: 'closed',
      });
      this.goBack();
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute(this.$route.params.projectId));
    },
  },
};
</script>

<style scoped></style>
